export const environment = {
  theme: '',
  production: true,
  name: 'production',
  apiUrl: 'https://backend.prod2.paymefy2.paymefy.com/graphql/',
  mapQuest: 'Iv2jb45oodNOetEP9ZkJlj0zRjtB21vg',
  GoogleMap: 'AIzaSyDMVXuDdx-5iE6gjdAgdxME5S-zmVzBSIo',
  appVersion: '1.4.0',
  isMockEnabled: false,
  auth: {
    domain: 'paymefy.eu.auth0.com',
    clientId: 'qzzVN1qavzdbASAmEv6si9QRvoS0hGkT',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://backend.paymefy.com',
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://backend.prod2.paymefy2.paymefy.com/graphql/',
          tokenOptions: {
            authorizationParams: {
              audience: 'https://backend.paymefy.com',
            },
          },
        }
      ]
    }
  }
};
